import React from 'react'

import App from 'next/app'
import NextHead from 'next/head'

import { SEO } from '~/components/Meta'
import StandardLayout from '~/layouts/Standard'
import { router } from '~/lib/router'
import colors from '~/styles/colors'
import { IsServer } from '~/utils'
import './_app.scss'

const NextJSApp = ({ Component, pageProps, currentRoute }) => {
  const currentRouteParsed = IsServer ? currentRoute : router.getCurrentRoute()

  return (
    <>
      <NextHead>
        <meta charSet='UTF-8' />
        <title>{SEO.title}</title>
        <meta name='description' content={SEO.description} />
        <link href='https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,700&display=swap' rel='stylesheet' />
        <link href='https://fonts.googleapis.com/css2?family=Courier+Prime&display=swap' rel='stylesheet' />
        <link href='https://fonts.googleapis.com/css2?family=Playfair+Display:wght@900&display=swap' rel='stylesheet' />
        <script src='https://cdn.lordicon.com/libs/mssddfmo/lord-icon-2.1.0.js' />

        <link rel='apple-touch-icon' sizes='57x57' href='/static/favicon/apple-icon-57x57.png' />
        <link rel='apple-touch-icon' sizes='60x60' href='/static/favicon/apple-icon-60x60.png' />
        <link rel='apple-touch-icon' sizes='72x72' href='/static/favicon/apple-icon-72x72.png' />
        <link rel='apple-touch-icon' sizes='76x76' href='/static/favicon/apple-icon-76x76.png' />
        <link rel='apple-touch-icon' sizes='114x114' href='/static/favicon/apple-icon-114x114.png' />
        <link rel='apple-touch-icon' sizes='120x120' href='/static/favicon/apple-icon-120x120.png' />
        <link rel='apple-touch-icon' sizes='144x144' href='/static/favicon/apple-icon-144x144.png' />
        <link rel='apple-touch-icon' sizes='152x152' href='/static/favicon/apple-icon-152x152.png' />
        <link rel='apple-touch-icon' sizes='180x180' href='/static/favicon/apple-icon-180x180.png' />
        <link rel='icon' type='image/png' sizes='192x192' href='/static/favicon/android-icon-192x192.png' />
        <link rel='icon' type='image/png' sizes='32x32' href='/static/favicon/favicon-32x32.png' />
        <link rel='icon' type='image/png' sizes='96x96' href='/static/favicon/favicon-96x96.png' />
        <link rel='icon' type='image/png' sizes='16x16' href='/static/favicon/favicon-16x16.png' />
        <link rel='manifest' href='/static/favicon/manifest.json' />
        <meta name='msapplication-TileColor' content={colors.divzGray} />
        <meta name='theme-color' content={colors.divzGray} />
      </NextHead>
      <StandardLayout currentRoute={currentRouteParsed}>
        <Component
          {...pageProps}
          currentRoute={currentRouteParsed}
        />
      </StandardLayout>
    </>
  )
}

NextJSApp.getInitialProps = async (appContext) => {
  // ? Call "super" to run page's `getInitialProps`
  let appProps

  if (App.getInitialProps) {
    appProps = await App.getInitialProps(appContext)
  }

  return {
    ...appProps,
    currentRoute: router.getCurrentRoute(appContext.ctx),
  }
}

export default NextJSApp

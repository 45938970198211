import { css } from '@emotion/react'

import colors from '~/styles/colors'
import { maxWidth } from '~/styles/constants'
import pagePadding from '~/styles/mixins/padding'

export const layout = css`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: auto;
  background: ${colors.backgroundDefault};
`

export const main = css`
  min-height: 100vh;
  
  > section {
    max-width: ${maxWidth};
    margin: auto;
    ${pagePadding};
  }

  h1, h2, h3, h4, h5, h6 {
    color: ${colors.textBlack};
  }
`

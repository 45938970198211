import React from 'react'

import Footer from '~/components/Footer'
import Header from '~/components/Header'

import * as style from './styles.js'

/**
 * StandardLayout
 *
 * @component
 * @usage `import StandardLayout from '~/layouts/Standard'`
 * @example
 * ! Wrap `<Component />` in `_app.js`:
 * <StandardLayout currentRoute={currentRouteParsed}>
 *    <Component {...} />
 * </StandardLayout>
 */

const StandardLayout = ({ children }) => {
  return (
    <div css={style.layout}>
      <Header />
      <main css={style.main}>
        {/* <Sidebar currentRoute={currentRoute} /> */}
        {children}
        <Footer />
      </main>
    </div>
  )
}

StandardLayout.propTypes = {
}

StandardLayout.defaultProps = {
}

export default StandardLayout

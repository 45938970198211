import colors from '~/styles/colors'

export const SEO = {
  sitename: 'Divz LLC',
  title: 'Divz LLC | Software sowlutions made quick and easy.',
  description: 'Let us help you take your idea or company to the next level by providing you with all-in-one quick and easy software solutions.',
  robots: 'noodp',
  keywords: 'divz, llc, io, company, software, mobile, app, seo, hardware',
  url: 'https://divz.io/',
  image: '/static/divz-share-image.png',
  tileColor: colors.divzBlue,
  tileImage: '/static/divz-share-image-square.png',
  color: colors.divzBlue,
}

export const OG = {
  locale: 'en_US',
  type: 'website',
  title: SEO.title,
  description: SEO.description,
  url: SEO.url,
  siteName: SEO.sitename,
  image: SEO.image,
}

export const TWITTER = {
  card: 'summary',
  title: SEO.title,
  description: SEO.description,
  url: SEO.url,
  image: SEO.image,
}

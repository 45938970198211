export const zIndexes = {
  header: 10,
  top: 3,
  middle: 2,
  one: 1,
  neutral: 0,
  under: -1,
  backlayer: -10,
}

export const HEADER_HEIGHT = '5rem'

// todo: Should not include `transition:`
export const transition = 'transition: 0.2s ease-in-out'

export const transitionBounce = 'transition: 0.4s cubic-bezier(0.68, -0.55, 0.27, 1.55)'

export const maxWidth = '80rem'

export const borderRadius = '0.5rem'

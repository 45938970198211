import { css } from '@emotion/react'
import { transparentize } from 'polished'

import colors from '~/styles/colors'
import { transition } from '~/styles/constants'

export const button = ({ color, isDisabled, isSolid }) => css`
  outline: none;
  background: none;
  border: 0.1rem solid ${colors[color]};
  color: ${colors[color]};
  padding: 0.75rem 1.5rem;
  border-radius: 3rem;
  font-weight: 600;
  font-size: 0.9rem;
  cursor: pointer;
  ${transition};
  transition-property: color, background-color, box-shadow;

  ${isSolid && `
    background-color: ${colors[color]};
    color: ${colors.white};
  `};

  ${!isDisabled && `
    &:hover {
      color: ${colors.white};
      background-color: ${colors[color]};
      box-shadow: 0rem 0.2rem 0.8rem -0.1rem ${colors[color]};
    }

    &:active {
      transition-duration: 0.1s;
      box-shadow: none;
      background-color: ${transparentize(0.3, colors[color])};
      border-color: ${transparentize(1, colors[color])}
    }
  `};

  ${isDisabled && `
    filter: grayscale(1);
    cursor: not-allowed;
  `}
`

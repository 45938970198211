import React, { Fragment } from 'react'

import { isFunction, isString } from 'lodash'
import PropTypes from 'prop-types'
import LazyLoad from 'react-lazyload'

import IMAGES from './images'

/**
 * Image
 *
 * @component
 * @usage `import Image from '~/components/Image'`
 */
const Image = ({ image, ...extra }) => {
  let ImageComponent = Fragment
  const foundImage = IMAGES[image]

  if (isString(foundImage)) {
    // eslint-disable-next-line react/display-name
    ImageComponent = (props) => <img src={foundImage} {...props} />
  } else if (isFunction(foundImage)) {
    ImageComponent = IMAGES[image]
  }

  return (
    <LazyLoad style={{ display: 'flex' }}>
      <ImageComponent {...extra} />
    </LazyLoad>
  )
}

Image.propTypes = {
  image: PropTypes.oneOf(Object.keys(IMAGES)),
}

Image.defaultProps = {
}

export default Image

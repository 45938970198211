import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import colors from '~/styles/colors'
import prefersReducedMotion from '~/utils/prefersReducedMotion'

import ICONS from './icons'
import ANIMATED_ICONS from './iconsAnimated'

import * as style from './styles'

const ICON_ID = 'divz-icon'

/**
 * Icon
 *
 * @component
 * @usage `import Icon from '~/components/Icon'`
 */
const Icon = ({ icon, animatedProps, ...extra }) => {
  if (ANIMATED_ICONS[icon] && !prefersReducedMotion) {
    const { style, ...restOfAnimatedProps } = animatedProps
    return (
      <div {...extra}>
        <lord-icon
          id={ICON_ID}
          src={`https://cdn.lordicon.com/${ANIMATED_ICONS[icon]}.json`}
          trigger='hover'
          colors={`primary:${colors.divzBlue},secondary:${colors.divzGreen}`}
          style={{ width: '2em', height: '2em', ...style }}
          stroke='75'
          {...restOfAnimatedProps}
        />
      </div>
    )
  }

  const IconComponent = ICONS[icon] || Fragment

  return (
    <IconComponent
      id={ICON_ID}
      css={style.icon}
      {...extra}
    />
  )
}

Icon.propTypes = {
  icon: PropTypes.oneOf(Object.keys(ICONS)),
  animatedProps: PropTypes.object,
}

Icon.defaultProps = {
  animatedProps: {},
}

export default Icon

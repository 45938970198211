import { css } from '@emotion/react'

import { mqMin } from '~/styles/mixins/mediaQueries'

export default css`
  ${mqMin.xs} {
    padding: 0 1rem;
  }

  ${mqMin.sm} {
    padding: 0 2rem;
  }

  ${mqMin.md} {
    padding: 0 3rem;
  }

  ${mqMin.lg} {
    padding: 0 4rem;
  }

  ${mqMin.xl} {
    padding: 0 5%;
  }
`

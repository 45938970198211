import { css } from '@emotion/react'

import colors from '~/styles/colors'
import { maxWidth, transition } from '~/styles/constants'
import { mqMax } from '~/styles/mixins/mediaQueries'

export const footer = css`
  background: ${colors.backgroundDivzGradient};
  margin-top: 5rem;
  padding: 1rem 0;
`

export const footerContent = css`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: ${maxWidth};
  margin: 5rem auto;
  color: ${colors.textGray};

  ${mqMax.lg} {
    margin: 5rem 2rem;
  }
`

export const footerLogo = css`
  width: 10rem;
  margin-bottom: 2rem;
`

export const footerContact = css`
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  margin-top: 1rem;
  color: ${colors.textGray};
  text-decoration: none;
  width: max-content;
  ${transition};

  &:hover {
    color: ${colors.divzBlue};
  }
`

export const contactIcon = css`
  color: ${colors.black};
  margin-right: 1rem;
  font-size: 1.25rem;
`

export const footerCopyright = css`
  position: absolute;
  font-size: 0.85rem;
  /* margin: 0 25%; */
  bottom: -5rem;
  color: ${colors.textGray};
`

import { BiHomeAlt, BiHeart } from 'react-icons/bi'
import { FaArrowUp, FaArrowDown, FaChevronLeft, FaStar } from 'react-icons/fa'
import { FcSimCardChip } from 'react-icons/fc'
import { FiShoppingBag } from 'react-icons/fi'
import { GiSpeedometer } from 'react-icons/gi'
import { IoShieldCheckmarkSharp, IoScanOutline, IoQrCodeOutline } from 'react-icons/io5'

// REF: https://iconmonstr.com/ (Usually 'Bold' icons)
// ? When adding icons, change the width and height to 1em in the SVG
import IconBandage from '~/public/static/icons/bandage.svg'
import IconBook from '~/public/static/icons/book.svg'
import IconBuild from '~/public/static/icons/brick-wall.svg'
import IconCheck from '~/public/static/icons/check.svg'
import IconCloud from '~/public/static/icons/cloud.svg'
import IconCreditCard from '~/public/static/icons/credit-card.svg'
import IconDatabase from '~/public/static/icons/database.svg'
import IconDevices from '~/public/static/icons/devices.svg'
import IconDiamond from '~/public/static/icons/diamond.svg'
import IconChartDots from '~/public/static/icons/dot-chart.svg'
import IconFlag from '~/public/static/icons/flag.svg'
import IconGit from '~/public/static/icons/git.svg'
import IconGlasses from '~/public/static/icons/glasses.svg'
import IconInfoCircle from '~/public/static/icons/info-circle.svg'
import IconMagic from '~/public/static/icons/magic.svg'
import IconEmail from '~/public/static/icons/mail.svg'
import IconMessageApprove from '~/public/static/icons/message-approve.svg'
import IconMessageReport from '~/public/static/icons/message-report.svg'
import IconPackage from '~/public/static/icons/package.svg'
import IconPackages from '~/public/static/icons/packages.svg'
import IconPaperPlane from '~/public/static/icons/paper-plane.svg'
import IconRefine from '~/public/static/icons/pave-brick-wall.svg'
import IconPhone from '~/public/static/icons/phone.svg'
import IconPlan from '~/public/static/icons/plan.svg'
import IconReusePackage from '~/public/static/icons/reuse-package.svg'
import IconSearch from '~/public/static/icons/search.svg'
import IconShuffle from '~/public/static/icons/shuffle.svg'
import IconWeb from '~/public/static/icons/web.svg'
import IconX from '~/public/static/icons/x.svg'

export default {
  email: IconEmail,
  phone: IconPhone,
  package: IconPackage,
  diamond: IconDiamond,
  glasses: IconGlasses,
  flag: IconFlag,
  send: IconPaperPlane,
  plan: IconPlan,
  build: IconBuild,
  refine: IconRefine,
  arrowUp: FaArrowUp,
  arrowDown: FaArrowDown,
  checkmark: IconCheck,
  info: IconInfoCircle,
  search: IconSearch,
  dotChart: IconChartDots,
  cloud: IconCloud,
  devices: IconDevices,
  web: IconWeb,
  creditCard: IconCreditCard,
  packages: IconPackages,
  bandage: IconBandage,
  simChip: FcSimCardChip,
  shieldCheckmark: IoShieldCheckmarkSharp,
  chevronLeft: FaChevronLeft,
  shoppingBag: FiShoppingBag,
  home: BiHomeAlt,
  heart: BiHeart,
  scan: IoScanOutline,
  qrCode: IoQrCodeOutline,
  star: FaStar,
  notebook: IconBook,
  database: IconDatabase,
  messageReport: IconMessageReport,
  circleCheck: IconMessageApprove,
  speedometer: GiSpeedometer,
  git: IconGit,
  recycle: IconReusePackage,
  x: IconX,
  shuffle: IconShuffle,
  magic: IconMagic,
}

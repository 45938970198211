import React from 'react'

import Icon from '~/components/Icon'
import Image from '~/components/Image'
import contactInfo from '~/contactInfo'

import * as style from './styles'

/**
 * <Describe the Footer component here>
 *
 * @component
 * @usage import Footer from '~/components/Footer'
 * @example
 * <Add an example of how Footer is used here>
 */
const Footer = () => {
  return (
    <footer css={style.footer}>
      <div css={style.footerContent}>
        <Image image='LogoGray' css={style.footerLogo} />
        {/* <a title='Call Us' href={`tel:${contactInfo.phone}`} css={style.footerContact}>
          <Icon icon='phone' css={style.contactIcon} /> {contactInfo.phone}
        </a> */}
        <a title='Email Us' href={`mailto:${contactInfo.email}`} css={style.footerContact}>
          <Icon icon='email' css={style.contactIcon} /> {contactInfo.email}
        </a>
        <span css={style.footerCopyright}>
        © {new Date().getFullYear()} Divz LLC. All Rights Reserved.
        </span>
      </div>
    </footer>
  )
}

Footer.propTypes = {
}

Footer.defaultProps = {
}

export default Footer

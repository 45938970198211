import React from 'react'

import { motion } from 'framer-motion'
import PropTypes from 'prop-types'

import colors from '~/styles/colors'

import * as style from './styles'

/**
 * <Describe the Button component here>
 *
 * @component
 * @usage import Button from '~/components/Button'
 * @example
 * <Add an example of how Button is used here>
 */
const Button = ({ color, isDisabled, animation, isSolid, ...extra }) => {
  return (
    <motion.button
      css={style.button({ color, isDisabled, isSolid })}
      disabled={isDisabled}
      {...extra}
      {...animation}
    />
  )
}

Button.propTypes = {
  color: PropTypes.oneOf(Object.keys(colors)),
  isDisabled: PropTypes.bool,
  isSolid: PropTypes.bool,
  animation: PropTypes.object,
}

Button.defaultProps = {
  color: 'divzBlue',
  isDisabled: false,
  isSolid: false,
  animation: {},
}

export default Button

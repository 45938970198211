import { transparentize } from 'polished'

import isDarkMode from '~/utils/isDarkMode'

const COLORS = {
  black: '#1D252C',
  gray: '#61666B',
  red: '#E7302A',
  yellow: '#EDDB56',
  blue: '#1174F2',
  white: '#ffffff',
  green: '#65e0b3',
  orange: '#F87D42',
  purple: '#5B49F8',
  transparent: '#ffffff00',
}

const DARKMODE_COLORS = {
  black: '#191c22',
  black2: '#262c33',
  gray: '#EBEBEB',
  offWhite: '#FCFDF7',
}

const THEME = {
  divzBlue: '#00A0DF',
  divzGreen: '#19D3C5',
  divzGray: '#1D252C',
  divzBlueGreen: '#79DEA8',
  textBlack: COLORS.black,
  textGray: COLORS.gray,
}

const DARKMODE_THEME = {
  textBlack: DARKMODE_COLORS.offWhite,
  textGray: DARKMODE_COLORS.gray,
}

const BACKGROUNDS = {
  backgroundDefault: COLORS.white,
  backgroundElement: COLORS.white,
  backgroundParticles: `linear-gradient(0deg, ${COLORS.white} 0%, ${COLORS.transparent} 20%)`,
  backgroundDivzGradient: `linear-gradient(90deg, ${transparentize(0.9, THEME.divzBlue)} 0%, ${transparentize(0.9, THEME.divzGreen)} 100%);`,
}

const DARKMODE_BACKGROUNDS = {
  backgroundDefault: DARKMODE_COLORS.black,
  backgroundElement: DARKMODE_COLORS.black2,
  backgroundParticles: `linear-gradient(0deg, ${DARKMODE_COLORS.black} 0%, ${COLORS.transparent} 20%)`,
  backgroundDivzGradient: `linear-gradient(90deg, ${transparentize(0.5, THEME.divzBlue)} 0%, ${transparentize(0.5, THEME.divzGreen)} 100%);`,
}


const MISC = {
  paypalYellow: '#F7BE38',
  bitcoinOrange: '#FF9800',
}

export default {
  ...COLORS,
  ...THEME,
  ...BACKGROUNDS,
  ...MISC,
}

import Blob1 from '~/public/static/blobs/blob-1.svg'
import Blob2 from '~/public/static/blobs/blob-2.svg'
import Blob3 from '~/public/static/blobs/blob-3.svg'
import Blob4 from '~/public/static/blobs/blob-4.svg'
import Blob5 from '~/public/static/blobs/blob-5.svg'
import Blob6 from '~/public/static/blobs/blob-6.svg'
import Blob7 from '~/public/static/blobs/blob-7.svg'
import Blob8 from '~/public/static/blobs/blob-8.svg'
import Blob9 from '~/public/static/blobs/blob-9.svg'
import LogoIcon from '~/public/static/divz-icon.svg'
import LogoGrayStacked from '~/public/static/divz-logo-gray-stacked.svg'
import LogoGray from '~/public/static/divz-logo-gray.svg'
import LogoWhiteStacked from '~/public/static/divz-logo-white-stacked.svg'
import LogoWhite from '~/public/static/divz-logo-white.svg'
import logoBitcoin from '~/public/static/logo-bitcoin.svg'
import logoPaypal from '~/public/static/logo-paypal.svg'
import logoVisa from '~/public/static/logo-visa.svg'
import partnerJosephAnthony from '~/public/static/partner-josephanthony.svg'
import partnerSowlutions from '~/public/static/partner-sowlutions.svg'
import Signature from '~/public/static/signature.svg'

const STATIC_PATH = '/static/'

const BRANDING = {
  LogoIcon,
  LogoGray,
  LogoWhite,
  LogoWhiteStacked,
  LogoGrayStacked,
  Signature,
}

export const BLOBS = {
  Blob1,
  Blob2,
  Blob3,
  Blob4,
  Blob5,
  Blob6,
  Blob7,
  Blob8,
  Blob9,
}

export const PARTNERS = {
  partnerSowlutions,
  partnerJosephAnthony,
}

// todo: make sure these are being used
export const MISC = {
  frameiPhone: `${STATIC_PATH}frame-iphone.png`,
  frameMacbook: `${STATIC_PATH}frame-macbook.png`,
  furnitureArmchair: `${STATIC_PATH}furniture-armchair.png`,
  furnitureChair: `${STATIC_PATH}furniture-chair.png`,
  furnitureCouch: `${STATIC_PATH}furniture-couch.png`,
  backgroundKitchenSpices: `${STATIC_PATH}background-kitchen-spices.png`,
  foodPlateChickenSalad: `${STATIC_PATH}food-plate-chicken-salad.png`,
  foodPlateSalmon: `${STATIC_PATH}food-plate-salmon.png`,
  foodPlateSkewers: `${STATIC_PATH}food-plate-skewers.png`,
  foodPlateChickenCurry: `${STATIC_PATH}food-plate-chicken-curry.png`,
  logoVisa,
  logoPaypal,
  logoBitcoin,
}

export default {
  ...BRANDING,
  ...BLOBS,
  ...PARTNERS,
  ...MISC,
}

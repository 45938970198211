import { css } from '@emotion/react'

import colors from '~/styles/colors'
import { HEADER_HEIGHT, zIndexes, maxWidth } from '~/styles/constants'
import boxShadow from '~/styles/mixins/boxShadow'
import { mqMax } from '~/styles/mixins/mediaQueries'
import pagePadding from '~/styles/mixins/padding'

const BORDER_THICKNESS = '0.1rem'

export const header = css`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: calc(${HEADER_HEIGHT} - ${BORDER_THICKNESS});
  width: -moz-available;
  width: -webkit-fill-available;
  width: fill-available;
  z-index: ${zIndexes.header};
  ${pagePadding};

  ${mqMax.lg} {
    display: none;
  }
`

export const headerBackground = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: ${colors.backgroundDefault};
  opacity: 0;
  ${boxShadow};
`

export const headerContent = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: calc(${maxWidth} + 20rem);
  margin: auto;
`

export const logoLink = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`

export const logo = css`
  width: 10rem;
`

import React, { Fragment } from 'react'

import NextHead from 'next/head'
import PropTypes from 'prop-types'

import {
  SEO as defaultSEO,
  TWITTER as defaultTwitter,
  OG as defaultOG,
} from '~/meta.config'

/**
 * Component to render all necessary SEO tags in the page.
 *
 * @component
 * @usage `import { SEO }, Meta from '~/components/Meta'`
 * @example
 * <Meta
 *  allTags={{
 *    ...SEO
 *    title: 'Changed Title',
 *    description: 'Changed Description',
 *  }}
 * />
 */
const Meta = ({ isHead, allTags, general, twitter, openGraph }) => {
  const SEO_GENERAL = { ...defaultSEO, ...allTags, ...general }
  const SEO_TWITTER = { ...defaultTwitter, ...allTags, ...twitter }
  const SEO_OPEN_GRAPH = { ...defaultOG, ...allTags, ...openGraph }

  const ParentTag = isHead ? NextHead : Fragment

  const shouldRenderGeneralTags = Object.keys(SEO_GENERAL).length > 0
  const shouldRenderOGTags = Object.keys(SEO_OPEN_GRAPH).length > 0
  const shouldRenderTwitterTags = Object.keys(SEO_TWITTER).length > 0

  return (
    <ParentTag>
      {/* SEO General */}
      {shouldRenderGeneralTags && (
        <Fragment>
          <title>{SEO_GENERAL.title}</title>
          <meta name='description' content={SEO_GENERAL.description} />
          <meta name='robots' content={SEO_GENERAL.robots} />
          <meta name='keywords' content={SEO_GENERAL.keywords} />
          <meta name='url' content={SEO_GENERAL.url} />
          <meta name='image' content={SEO_GENERAL.image} />
          <meta name='msapplication-TileColor' content={SEO_GENERAL.tileColor} />
          <meta name='msapplication-TileImage' content={SEO_GENERAL.tileImage} />
          <meta name='theme-color' content={SEO_GENERAL.color} />
        </Fragment>
      )}
      {/* SEO OG */}
      {shouldRenderOGTags && (
        <Fragment>
          <meta key='meta_og-locale' property='og:locale' content={SEO_OPEN_GRAPH.locale} />
          <meta key='meta_og-type' property='og:type' content={SEO_OPEN_GRAPH.type} />
          <meta key='meta_og-title' property='og:title' content={SEO_OPEN_GRAPH.title} />
          <meta key='meta_og-description' property='og:description' content={SEO_OPEN_GRAPH.description} />
          <meta key='meta_og-url' property='og:url' content={SEO_OPEN_GRAPH.url} />
          <meta key='meta_og-site_name' property='og:site_name' content={SEO_OPEN_GRAPH.siteName} />
          <meta key='meta_og-image' property='og:image' content={SEO_OPEN_GRAPH.image} />
        </Fragment>
      )}
      {/* SEO Twitter */}
      {shouldRenderTwitterTags && (
        <Fragment>
          <meta key='meta_twitter-card' name='twitter:card' content={SEO_TWITTER.card} />
          <meta key='meta_twitter-title' name='twitter:title' content={SEO_TWITTER.title} />
          <meta key='meta_twitter-description' name='twitter:description' content={SEO_TWITTER.description} />
          <meta key='meta_twitter-site' name='twitter:site' content={SEO_TWITTER.url} />
          <meta key='meta_twitter-image' name='twitter:image' content={SEO_TWITTER.image} />
        </Fragment>
      )}
    </ParentTag>
  )
}

Meta.propTypes = {
  /**
	 * Whether or not these tags should be wrapped in the <head /> tag // ? Should be false if component is inside of HTML <head />
	 */
  isHead: PropTypes.bool,
  /**
	 * SEO properties that apply to all SEO tags // ? [Recommended]
	 */
  allTags: PropTypes.object,
  /**
	 * SEO properties that are seen in search engines but do not affect other SEO tags
	 */
  general: PropTypes.object,
  /**
	 * SEO properties specific to Twitter only
	 */
  twitter: PropTypes.object,
  /**
	 * SEO properties specific to OpenGraph (Facebook) only
	 */
  openGraph: PropTypes.object,
}

Meta.defaultProps = {
  isHead: true,
  allTags: {},
  general: {},
  twitter: {},
  openGraph: {},
}

export { defaultSEO as SEO }
export default Meta

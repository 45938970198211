
import React from 'react'

import HttpStatus from 'http-status-codes'
import { isEmpty, trim, isObject } from 'lodash'
import NextLink from 'next/link'
import NextRouter from 'next/router'
import PropTypes from 'prop-types'

import appRoutes from '~/routes'
import { Regex, IsServer } from '~/utils'

const createRoute = ({ href, params }) => {
  // ? In case people accidentally pass the object instead of the string:
  const hrefParsed = isObject(href) ? href.path : href

  let templatePath = hrefParsed
  let actualPath = hrefParsed

  if (isEmpty(params)) {
    return { templatePath, actualPath }
  }

  templatePath = hrefParsed.replace(Regex(Regex.patterns.paramsStrict), variable => {
    const variableTrimmed = trim(variable, ':')
    return `[${variableTrimmed}]`
  })

  actualPath = hrefParsed.replace(Regex(Regex.patterns.paramsStrict), variable => {
    const variableTrimmed = trim(variable, ':')
    return `${params[variableTrimmed]}`
  })

  return { templatePath, actualPath }
}

const extractBasePath = (path, exp = Regex.patterns.firstParamAndOnward) => {
  return path.replace(Regex(exp), '')
}

/**
 * Retrieves app routes or paths and presents them.
 *
 * @function
 * @param {object} routes - Object containing the app routes | Example: { home: '/home/:id' }
 * @returns {object} - | Example: { home: { path: '/home/:id', basePath: '/home', ... } }
 */
const presentRoutes = routes => {
  const routesPresented = {}
  const allRouteKeys = Object.keys(routes)

  allRouteKeys.forEach(routeKey => {
    let routeValue = routes[routeKey]

    if (!isObject(routeValue)) {
      // ? If `routeValue` is a string
      routeValue = { path: routeValue }
    }

    const basePath = extractBasePath(routeValue.path)
    routesPresented[routeKey] = {
      ...routeValue,
      basePath,
    }
  })

  return routesPresented
}

/**
 * Organizes app routes or paths by url rather than by key.
 *
 * @function
 * @param {object} baseRoutes - Object containing the *absolute* app routes | Example: { home: '/home' }
 * @returns {object} - | Example: { '/home': 'home' }
 */
const getRoutesByPath = routesPresented => {
  const reversedObject = {}
  Object.keys(routesPresented).forEach(routeKey => {
    const routeValue = routesPresented[routeKey]
    reversedObject[routeValue.basePath] = {
      key: routeKey,
      ...routeValue,
    }
  })

  return reversedObject
}

/**
 * Returns the current route and related parameters.
 *
 * @param {object} routesByPath - Object of routes organized by path instead of by key
 * @param {object} [ctx={}] - [Optional] The current context. To be used if there is a possibility of calling this function serverside
 */
const getCurrentRoute = (routesByPath, ctx = {}, NextRouter) => {
  if (!isEmpty(ctx)) {
    const basePath = extractBasePath(ctx.pathname, Regex.patterns.firstNextJsParamAndOnward)
    return {
      currentPath: ctx.asPath,
      query: ctx.query,
      ...routesByPath[basePath],
    }
  }

  if (!IsServer) {
    const basePath = extractBasePath(NextRouter.route, Regex.patterns.firstNextJsParamAndOnward)
    return {
      currentPath: NextRouter.asPath,
      query: NextRouter.query,
      ...routesByPath[basePath],
    }
  }

  return {}
}

/**
 * Push redirects server-side and client-side.
 *
 * @function
 * @usage `import { router } from '~/lib/router'`
 * @param {string} [href=''] - The path to redirect to
 * @param {object} [params={}] - The params that will be used in the path
 * @param {object} [ctx={}] - [Optional] The current context. To be used if there is a possibility of calling this function serverside
 * @example
 * ? Will redirect to /foobar/[id=1]/[slug=2]
 * router.push(router.routes.foobar, { id: 1, slug: 2 }, ctx)
 */
const routerPush = (href = '', params = {}, ctx = {}, NextRouter) => {
  const { templatePath, actualPath } = createRoute({ href, params })

  if (ctx.res) {
    ctx.res.writeHead(HttpStatus.MOVED_TEMPORARILY, {
      Location: actualPath,
    })
    ctx.res.end()
  } else {
    NextRouter.push(templatePath, actualPath)
  }
}

/**
 * Replace redirects server-side and client-side.
 *
 * @usage `import { router } from '~/lib/router'`
 * @param {string} [href=''] - The path to redirect to
 * @param {object} [params={}] - The params that will be used in the path
 * @param {object} [ctx={}] - [Optional] The current context. To be used if there is a possibility of calling this function serverside
 * @example
 * ? Will redirect to /foobar/[id=1]/[slug=2]
 * router.replace(router.routes.foobar, { id: 1, slug: 2 }, ctx)
 */
const routerReplace = (href = '', params = {}, ctx = {}, NextRouter) => {
  const { templatePath, actualPath } = createRoute({ href, params })

  if (ctx.res) {
    ctx.res.writeHead(HttpStatus.MOVED_TEMPORARILY, {
      Location: actualPath,
    })
    ctx.res.end()
  } else {
    NextRouter.replace(templatePath, actualPath)
  }
}

/**
 * Next Link with dynamic router.
 *
 * @component
 * @usage `import { Link } from '~/lib/router'`
 * @param {string|array} [href=''] - The path {string} or dynamic path {array} to redirect to
 * @example
 * ? Will redirect to /foobar/[id=1]/[slug=2]
 * <Link href={router.routes.foobar.path} params={{id: 1, slug: 2}}>
 *  Click me!
 * </Link>
 */
const Link = ({ href, params, children, ...extra }) => {
  const { templatePath, actualPath } = createRoute({ href, params })

  return (
    <NextLink href={templatePath} as={actualPath}>
      <a {...extra}>{children}</a>
    </NextLink>
  )
}

Link.propTypes = {
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  params: PropTypes.object,
}

Link.defaultProps = {
  href: '',
  params: {},
}

const routesPresented = presentRoutes(appRoutes)
const routesByPath = getRoutesByPath(routesPresented)

const router = {
  replace: (href, params, ctx) => routerReplace(href, params, ctx, NextRouter),
  push: (href, params, ctx) => routerPush(href, params, ctx, NextRouter),
  routes: routesPresented,
  routesByPath,
  getCurrentRoute: (ctx) => getCurrentRoute(routesByPath, ctx, NextRouter),
}

export {
  router,
  Link,
}

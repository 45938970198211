// REF: https://lordicon.com/icons

export default {
  glasses: 'fjfoiejl', // Review / Thumbs up
  email: 'tkgyrmwc',
  phone: 'zxbujmvx', // Ringing Phone
  package: 'tbfvkcmb',
  diamond: 'vykmpjlc',
  flag: 'yqputury',
  plan: 'xbmrwjcg',
  build: 'qrmuiomk', // Bricks
  refine: 'ikwcijxm', // Trowel
  search: 'urhcgqxz', // Web Analytics
  dotChart: 'oomcqstl', // Radial Bar Chart
  cloud: 'wcjauznf',
  devices: 'grdobqew', // Phone
  web: 'wvrcwotf',
  creditCard: 'djoczfvh',
  packages: 'ggmwbqqw',
  bandage: 'vcrjhdyd',
  notebook: 'wxnxiano',
  database: 'izmjkhuc', // Servers
  messageReport: 'zpxybbhl', // Consultation
  circleCheck: 'gcgvfelb', // Fibonacci Arcs
  speedometer: 'haevqykp',
  git: 'ikqgsroi', // Calipers
  recycle: 'nxaaasqe', // Arrows Circles
  shuffle: 'wovwohsm', // Dice
  magic: 'njhpxsof', // Genie
}

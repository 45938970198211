import React, { useEffect } from 'react'

import Button from '~/components/Button'
import Image from '~/components/Image'
import { Link, router } from '~/lib/router'

import * as style from './styles'

const FULL_HEADER_OPACITY_AT = 80 // %

/**
 * Header / Navbar
 *
 * @component
 * @usage `import Header from '~/components/Header'`
 */
const Header = () => {
  const currentRoute = router.getCurrentRoute()

  useEffect(() => {
    const handleScroll = () => {
      const scrollPercentage = window.scrollY / window.innerHeight * 100
      const headerBackgroundOpacity = scrollPercentage / FULL_HEADER_OPACITY_AT
      const headerBackgroundElement = document.getElementById('header-background')
      headerBackgroundElement.style.opacity = headerBackgroundOpacity
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <header css={style.header}>
      <div id='header-background' css={style.headerBackground} />
      <div css={style.headerContent}>
        <Link title='Go Home' href={router.routes.home} css={style.logoLink}>
          <Image image='LogoGray' css={style.logo} />
        </Link>
        {
          currentRoute.key !== 'contact' && (
            <Link title='Contact Us' href={router.routes.contact}>
              <Button>Contact Us</Button>
            </Link>
          )
        }
      </div>
    </header>
  )
}

Header.propTypes = {
}

Header.defaultProps = {
}

export default Header
